
import { useForm } from "react-hook-form"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container, Row, Col, } from 'react-bootstrap'
import { useNavigate, BrowserRouter, Routes, Route } from "react-router-dom";
import Select from "react-select";
import useFetch from "../Componentes/useFetch";
import { Link } from "react-router-dom";
import '../Estilos/ordenes.css';


const Verods = (props) => {

  const styles = {

    img: { height: "60px", width: "230px", paddingLeft: "120px" },
    header: {
      backgroundColor: "rgb(0, 0, 0)",
      width: "400px",

      color: "#008b8b",
     
  },
 
  }



  const { register, setValue, handleSubmit, formState: { errors } } = useForm();

  const location = useLocation();

  const elemento = location.state;


  const [msg, setMsg] = useState('');
  const [datos, setDatos] = useState([]);
  const [suma, setSuma] = useState({});
  const [guardado, setGuardado] = useState(false)
  const [resoluciones, setResolucion] = useState([])
  //  const { data, loading } = useFetch("http://localhost/BVC/index.php/Resolucion",)




  const handleselectchange = (event) => {
    console.log(event.value)

    setValue('cod_resol', event.value)

  }

  const newelem = {
    numrec: elemento.numrec,
    apellido: elemento.apellido,
    calle: elemento.calle,
    direccion: elemento.direccion,
    resolucion: elemento.resolucion,

  }

  let navigate = useNavigate();
  let goBack = () => {
    navigate(-1);
  };



  // const onSubmit = (data) => console.log(data)

  const onSubmit = data => {

    setMsg('');



    setSuma({

      ...data,
      ...newelem
    })



    fetch("https://dcocce.host/BVC/index.php/Rutas/Cerrar_json", {
      method: "POST",
      body: JSON.stringify(suma)
    })
      .then(res => res.json())
      .then(resp => {



        setGuardado(resp.guardado);
        setMsg(resp.mensaje);

      })

  };

  useEffect(
    () => {


      //fetch("https://dariococce.000webhostapp.com/Instrumentos/",)
      fetch("https://dcocce.host/BVC/index.php/Resolucion",)

        .then(res => res.json())
        .then(data => {

          setResolucion(data);
          console.log(resoluciones)


        })




    },

    []

  )

  return (

    <div className="d-flex flex-column">


<div>
  <p></p>
</div>
<div className="d-flex justify-content-center">
      <Card style={styles.header} className="text-center">
        <Card.Img variant="top" style={styles.img} src="img/bvcplay.png" />
        <Card.Body>
          <Card.Title className="text-center">Consultar Orden</Card.Title>
          <Card.Text >



            <form onSubmit={handleSubmit(onSubmit)}>



              <table>

              <tr>
                  <td>

                    <label>N° Internet</label>
                  </td>
                  <td>

                    <input type="text" disabled
                      value={elemento.ni} />


                  </td>
                </tr>
                <tr>
                  <td>

                    <label>N° Cable</label>
                  </td>
                  <td>

                    <input type="text" disabled
                      value={elemento.nc} />


                  </td>
                </tr>
                <tr>
                  <td>

                    <label>Apellido</label>
                  </td>
                  <td>

                    <input type="text" disabled
                      value={elemento.apellido} />


                  </td>
                </tr>
              


                <tr>
                  <td>

                    <label>Direccion</label>
                  </td>
                  <td>

                    <input type="text" disabled
                      value={elemento.direccion} />


                  </td>
                </tr>

                <tr>
                  <td>

                    <label>Falla</label>
                  </td>
                  <td>

                  <textarea cols={23} rows={2} disabled
                      value={elemento.observaciones} />


                  </td>
                </tr>

                <tr>
                  <td>

                    <label>Fecha Cierre</label>
                  </td>
                  <td>

                    <input type="date" 
                      disabled
                      value={elemento.fecha_cump} />


                  </td>
                </tr>

                <tr>
                  <td>

                    <label>Hora</label>
                  </td>
                  <td>

                    <input type="text" 
                      disabled
                      value={elemento.hora} />
                

                  </td>
                </tr>

                <tr>
                  <td>

                    <label>Codigo</label>
                  </td>
                  <td>
                   
                    <input type="text"
                      disabled
                      value={elemento.cod_resol} />


                  </td>
                </tr>

                <tr>
                  <td>

                    <label>Resolucion</label>
                  </td>
                  <td>
                   
                    <input type="text"
                      disabled
                      value={elemento.descripcion} />


                  </td>
                </tr>

                <tr>
                  <td>

                    <label>Observaciones</label>
                  </td>
                  <td>

                  <textarea cols={20} rows={5} value={elemento.resolucion}
                      {...register("resolucion", { required: true })} />
                    



                  </td>
                </tr>
                </table>

                <br />



                <br />

                
                

                  <div className="d-grid gap-2">
                  <Button variant="primary" onClick={goBack} >VOLVER</Button>
                </div>




               


               
                    {msg ? <p>{msg}</p> : null}
                 
              

              <div>

              </div>
            </form>

          </Card.Text>

        </Card.Body>
      </Card>

      </div>

    </div>



  );
}

export default Verods;
