import { useState, useEffect } from "react"

function useFetchgen(url) {

    const [dato, setDato] = useState(null)
    const [loading, Setloading] = useState(true)
   
    

    useEffect(() => {

        Setloading(true)
        fetch(url)

            .then(res => res.json())
            .then(data => {

                Setloading(true)
               setDato(data)
                console.log(data)
                
                             
               

                Setloading(false)


            })



    },

        []



    );

    return { dato, loading};



}

export default useFetchgen;
