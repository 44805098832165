import React from 'react'
import { useState } from 'react'
import axios from 'axios';

function usePost() {
  const [respuesta, Setrespuesta] = useState({})

const [posdata, Setposdata] = useState(
{
  pending: true,
  resp: undefined,
  error: undefined,


}
)



const ejecutar = ({url, data}) => {
  console.log(data)

  
  //axios.post(url, data)
 // .then(response => {   "https://dcocce.host/BVC/index.php/Rutas/Cerrar_json"

  fetch(url, {
    method: "POST",
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(resp => {

console.log(resp)

Setrespuesta(resp)

      Setposdata(
        {
          pending: false,
       resp: resp.mensaje,
        error: undefined,
      
        }
      )
  

    })
   
       

        .catch(error => {

          Setposdata(
            {
              pending: false,
            resp: undefined,
            error: error.message,
          
            }
          )

        })
}

  return { ...posdata, respuesta, ejecutar}
    
  
}

export default usePost;